<template>
	<div>
		<div
			v-if="user.current.connectedToTrello"
			:class="['search trello', 'search-' + containerObj]"
		>
			<Card
				:name="`Trello / ${root.name}`"
				:actions="[
					{
						fnClick: () => next(root),
						icon: 'sync'
					}
				]"
				/>

			<div v-if="searching" class="result-searching">Searching...</div>

			<Card
				v-if="!results.length && !searching"
				class="active active fade"
				name="No Results"
				theme="info"
				icon="info-circle"
				/>

			<Card
				v-for="(result, i) in results"
				:key="i"

				class="text light"
				:name="result.name"
				:icon="getIcon(result.obj)"
				:fnClick="
					canOpen.includes(result.obj) ? () => next(result) : null
				"
				:actions="[
					canSelect.includes(result.obj)
						? {
								fnClick: () => select(result),
								icon: 'check',
							}
						: null,
				]"
			/>

			<Card
				v-if="showBackButton"
				class="light"
				name="Back"
				icon="arrow-left"
				:fnClick="() => back()"
			/>

		</div>
		<div class="requirement" v-else>
			<div class="requirement-title">Trello Not Connected</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { getSearchResults } from "../../helpers/api/trello";
import Card from "../Card";

export default {
	components: { Card },
	name: "Search",
	props: {
		value: {},
		canOpen: {
			type: Array,
			default: () => ["board", "list"],
		},
		canSelect: {
			type: Array,
			default: () => ["card"],
		},
		root: {
			type: Object,
			default: () => ({ obj: "member", name: "All" })
		}
	},
	data: () => ({
		history: [],
		current: null,
		results: [],
		searching: false,
	}),
	computed: {
		...mapGetters({
			user: "user/data",
		}),
		showBackButton() {
			return this.current && (this.current.obj != this.root.obj || this.current.id != this.root.id);
		},
		containerObj() {
			if (!this.current) return null;

			return this.current.obj;
		},
	},
	methods: {
		selectBoard(id) {
			this.$emit("input", { obj: "board", id });
		},
		selectList(id) {
			this.$emit("input", { obj: "list", id });
		},
		selectCard(id) {
			this.$emit("input", { obj: "card", id });
		},
		async back() {
			if (this.history.length > 0) {
				this.open(this.history.pop());
			} else this.open(null);
		},
		async next(option) {
			this.history.push(this.current);
			return await this.open(option);
		},
		async open(option) {
			if (option == null) option = { obj: "member" };

			this.current = option;
			this.searching = true;

			const res = await getSearchResults(this.current);
			console.log(res);
			if (!res.status == 200) return;

			this.results = res.data.data;
			this.searching = false;
		},
		async select(option) {
			this.$emit("input", {
				obj: option.obj,
				id: option.id,
				name: option.name,
			});
		},
		getIcon(obj) {
			switch (obj) {
				case "board": return "columns";
				case "list": return "bars";
				case "card": return "credit-card-blank";
				default: return "circle"; 
			}
		}
	},
	async created() {
		if (!this.user.current.connectedToTrello) return;

		this.history = [];
		await this.next(this.root);
	},
};
</script>
