import axios from "axios";
import { apiRoot } from "@/config";

export const get = async path => {
    const res = await axios.get(`${apiRoot}${path}`);
    if (res)
        return res;

    return { status: 500 };
}
export const list = async path => await axios.get(`${apiRoot}${path}`);
