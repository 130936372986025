<template>
    <div
        :class="['mask', { active: dialog.active }]"
        @click="maskClick($event)"
    >
        <div class="action-bar-container">
            <div class="action-bar-row">
                <a
                    href="#"
                    class="btn btn-action"
                    @click.stop.prevent="clearDialog"
                >
                    <i class="fal fa-fw fa-times"></i>
                </a>
                <a
                    href="#"
                    class="btn btn-action"
                    @click.stop.prevent="popDialog"
                >
                    <i class="fal fa-fw fa-arrow-left"></i>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    computed: {
        ...mapGetters({
            dialog: "dialog"
        })
    },
    methods: {
        ...mapActions({
            clearDialog: "clearDialog",
            popDialog: "popDialog",
        }),
        maskClick(e) {
            if (e.defaultPrevented)
                return;

            this.popDialog();
        }
    }
}
</script>