<script>
export default {
	props: {
		name: {
			type: String,
		},
		state: String,
		icon: String,
		theme: {
			type: String,
			default: "default",
		},
		progressVisible: Boolean,
		progress: Number,
		units: Array,
		actions: Array,
		to: String,
		fnClick: {
			type: Function,
			default: null,
		},
	},
	methods: {
		renderActions() {
			if (!this.actions) return null;

			let actions = [];
			this.actions
				.filter((x) => x != null && x.show !== false)
				.forEach((action) => {
					let badge = null;
					if (action.badge)
						badge = (<span class={['badge', action.badgeTheme]}>{action.badge}</span>);

					let icon = null;
					if (action.icon)
						icon = (<i class={"fad fa-fw fa-" + action.icon}></i>);

					let text = null;
					if (action.text)
						text = (<span class="fa-fw">{action.text}</span>)

					actions.push(
                        <a
                            class={{ active: action.active || false }}
							href="#"
							onClick={(e) => {
                                if (e.defaultPrevented)
                                    return;

								e.preventDefault();
								action.fnClick();
							}}
						>
							{icon}
							{badge}
							{text}
						</a>
					);
				});

			return <div class="card__actions">{actions}</div>;
		},
		renderUnits() {
			if (!this.units) return null;

			let units = [];
			this.units
				.filter((x) => x)
				.forEach((unit) => {
					let elapsed = null;
					if (unit.elapsed !== undefined)
						elapsed = (
							<div class="unit__elapsed">{unit.elapsed}</div>
						);

					let value = null;
					if (unit.value !== undefined)
						value = <div class="unit__value">{unit.value}</div>;

					let behaviour = null;
					if (unit.behaviour !== undefined)
						behaviour = (
							<div class="unit__behaviour">
								<i class={"fad fa-fw fa-" + unit.behaviour}></i>
							</div>
						);

					units.push(
						<div class="unit">
							{elapsed}
							{value}
							{behaviour}
						</div>
					);
				});

			return <div class="card__units">{units}</div>;
		},
		renderCard() {
			let icon = null;
			if (this.icon)
				icon = (
					<div class="card__icon">
						<i class={"fad fa-fw fa-" + this.icon}></i>
					</div>
				);

			let state = null;
			if (this.state) state = <div class="card__state">{this.state}</div>;

			let progress = null;
			if (this.progressVisible)
				progress = <div class="card__progress"></div>;

			return (
				<div>
					<div class="card-body">
						{icon}
						<div
							class="card__name"
							domPropsInnerHTML={this.name}
						></div>
						{state}
						{this.renderUnits()}
						{this.renderActions()}
					</div>
					{progress}
				</div>
			);
		},
		click(e) {
            if (e.defaultPrevented)
                return;
            
            e.preventDefault();
            if (this.to) this.$router.push(this.to);
            if (this.fnClick) this.fnClick();
		},
	},
	render() {
		if (this.to || this.fnClick)
			return (
				<a
					class={["card card-" + this.theme, { "card-with-icon": this.icon != null, "card-with-units": this.units != null }]}
					href="#"
					onClick={this.click}
				>
					{this.renderCard()}
				</a>
			);

		return <div class={["card card-" + this.theme, { "card-with-icon": this.icon != null, "card-with-units": this.units != null }]}>{this.renderCard()}</div>;
	},
};
</script>
