import axios from "axios";
import { isnull } from "../objects";
import { get } from "./index";

export const getSearchResults = async ({ obj, id }) => await get(`/search/trello?obj=${isnull(obj,'')}&id=${isnull(id,'')}`);

export const getMember = async ({ key, token }) => {
	const res = await axios.get(
		`https://api.trello.com/1/members/me?key=${key}&token=${token}`
	);
	if (res.status != 200) return null;

	const boardIds = res.data.idBoards;
	return {
		id: res.data.id,
		username: res.data.username,
		boardIds,
	};
};

export const getMemberBoards = async ({ key, token }) => {
	const res = await axios.get(
		`https://api.trello.com/1/members/me/boards?key=${key}&token=${token}`
	);
	if (res.status != 200) return null;

	return res.data
		.filter((x) => !x.closed)
		.map((board) => ({
			id: board.id,
			obj: "board",
			name: board.name,
			desc: board.desc,
			url: board.shortUrl,
			closed: board.closed,
		}));
};
export const getBoard = async (id, { key, token }) => {
	const res = await axios.get(
		`https://api.trello.com/1/boards/${id}?key=${key}&token=${token}`
	);
	if (res.status != 200) return null;

	return {
		id: res.data.id,
		name: res.data.name,
		desc: res.data.desc,
		url: res.data.shortUrl,
	};
};

export const getBoardLists = async (id, { key, token }) => {
	const res = await axios.get(
		`https://api.trello.com/1/boards/${id}/lists?key=${key}&token=${token}`
	);
	if (res.status != 200) return null;

	return res.data
		.filter((x) => !x.closed)
		.map((list) => {
			return {
				id: list.id,
				obj: "list",
				name: list.name,
				closed: list.closed,
			};
		});
};

export const getListCards = async (id, { key, token }) => {
	const res = await axios.get(
		`https://api.trello.com/1/lists/${id}/cards?key=${key}&token=${token}`
	);
	if (res.status != 200) return null;

	return res.data
		.filter((x) => !x.closed)
		.map((card) => {
			return {
				id: card.id,
				obj: "card",
				name: card.name,
				desc: card.desc,
				url: card.shortUrl,
				closed: card.closed,
			};
		});
};
